import React, {FC} from "react"
import cn from "classnames";

import Numbers, {Number} from './Numbers.types';

const list:Number[] = [
    {code: 'es', phone: '+34 91 215 8127'},
    {code: 'cn', phone: '+86 400 819 1292'},
    {code: 'do', phone: '+1 829 954 0405'},
    {code: 'ph', phone: '+63 28 540 1745'},
    {code: 'us', phone: '+1 646 722 4762'},
    {code: 'gb', phone: '+44 207 660 8020'},
    {code: 'ca', phone: '+1 833 420 0983'},
    {code: 'ua', phone: '+380 89 324 31 05'},
]

export const contactUsPageNumbers = list;

export const Numbers:FC<Numbers> = ({}):React.ReactElement => {
    return <li className={cn('contact__numbers')}>
        <ul className={cn('contact__numbers-list')}>
            {list.map((number:Number) => <li key={number.code} className={cn('contact__numbers-item')}>
                <i className={cn('contact__numbers-flag', 'flag-icon', `flag-icon-${number.code}`)}/>
                <a href={`tel:${number.phone}`}>{number.phone}</a>
            </li>)}
        </ul>
    </li>;
}
