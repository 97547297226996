import BaseScheme, {BaseSchemeType} from "./BaseScheme";
import { MainEntityOfPage, Publisher } from "./NewsArticleScheme";

type AboutPageType = BaseSchemeType & {
  "@type": "AboutPage"
  publisher: Publisher
  mainEntityOfPage: MainEntityOfPage
}

class AboutPageScheme extends BaseScheme<AboutPageType> {
  constructor(private path: string) {
    super(path, true);
    super.set('@type', 'AboutPage');

    super.set('publisher', {
      "@type": "Organization",
      "name": AboutPageScheme.url('/'),
      "logo": {
        "@type": "ImageObject",
        "url": AboutPageScheme.url('/images/global/logo-dfa-light.svg')
      },
      "sameAs": [
        "https://www.linkedin.com/company/df-alliance/",
        "https://www.facebook.com/df-alliance/",
        "https://twitter.com/df-alliance/"
      ]
    })
  }

  setMainEntityOfPage(): this {
    super.set('mainEntityOfPage', {
      '@type': 'WebPage',
      '@id': 'https://www.df-alliance.com' + this.path
    });
    return this;
  }
}

export default AboutPageScheme;
