import {Record} from "../../utils/types";
import {TermsData} from "../../sections/Resources/FreightDictionary/data";

export default (() => {
  const termsObj: Record<string, string> = {}

  TermsData.map(({terms}) => {
    terms.map(({url}) => {
      const key = `/freight-dictionary/${url}`;
      termsObj[key] = '/freight-dictionary/[title]'
    })
  })

  return {
    ...termsObj
  }
})()
