import BaseScheme, {BaseSchemeType} from "./BaseScheme";

export type Breadcrumb = {
  name: string
  url: string
}

type BreadcrumbElement = {
  "@type": "ListItem"
  position: number
  name?: string
  item: string
}

export type BreadcrumbListSchemeType = BaseSchemeType & {
  "@type": 'BreadcrumbList'
  itemListElement: BreadcrumbElement[]
}

class BreadcrumbListScheme extends BaseScheme<BreadcrumbListSchemeType> {
  constructor(path?: string | null) {
    console.log('path', path)
    super(path ?? null, false);
    super.set("@type", "BreadcrumbList");
  }

  public setItemListElement(value: Breadcrumb[], inside?: boolean): this {
    super.set('itemListElement', value.map(({url, name}: Breadcrumb, index: number): BreadcrumbElement => ({
      "@type": "ListItem",
      position: index + 1,
      name,
      item: BreadcrumbListScheme.url(url)
    })),);
    return this;
  }
}

export default BreadcrumbListScheme
