import BaseScheme, {BaseSchemeType} from "./BaseScheme";

type ContactPointType = {
  '@type': 'ContactPoint'
  telephone: string
  contactType: string
}

export type OrganizationSchemeType = BaseSchemeType & {
  '@type': 'Organization'
  logo?: string
  image?: string
  contactPoint?: ContactPointType[]
}

class OrganizationScheme extends BaseScheme<OrganizationSchemeType> {
  constructor(path: string, base?: boolean) {
    super(path, base);
    super.set('@type', 'Organization');
  }

  setContactPoint(contactPoint: string[]) {
    this.set('contactPoint', contactPoint.map((telephone) => ({
      '@type': 'ContactPoint',
      telephone,
      contactType: 'customer service',
    })));
    return this;
  }
}

export default OrganizationScheme
