import BaseScheme, {BaseSchemeType} from "./BaseScheme";

type OrganizerType = {
  "@type": "Organization"
  name: string
  url: string
  logo: {
    "@type": "ImageObject"
    url: string
    width: number
    height: number
  }
}

type PerformerType = {
  "@type": "Organization"
  name: string
}

type OfferType = {
  "@type": "Offer"
  url: string
  price: string
  priceCurrency: string
  availability: string
  validFrom: string
}

type OnlineLocationType = {
  "@type": "VirtualLocation"
  url: string
}

type LocationType = {
  "@type": "Place"
  name: string
  address?: {
    "@type": "PostalAddress"
    addressLocality: string
    addressCountry: string
    streetAddress?: string
  }
}

type LocationEnum = 'online' | 'Dubai Exhibition Centre, Expo City'

type BusinessEventType = BaseSchemeType & {
  "@type": "BusinessEvent"
  startDate: string
  endDate: string
  eventStatus: string
  eventAttendanceMode: string
  organizer: OrganizerType
  performer: PerformerType
  offers: OfferType
  location?: LocationType | OnlineLocationType
}

class BusinessEventScheme extends BaseScheme<BusinessEventType> {
  constructor(private path: string) {
    super(path, true);
    super.set('@type', 'BusinessEvent');
    super.set('eventStatus', 'https://schema.org/EventScheduled');
    super.set('eventAttendanceMode', 'https://schema.org/OfflineEventAttendanceMode');

    super.set('organizer', {
      "@type": "Organization",
      "name": "DF Alliance",
      "url": BusinessEventScheme.url('/'),
      "logo": {
        "@type": "ImageObject",
        "url": BusinessEventScheme.url('/images/global/logo-dfa-light.svg'),
        "width": 250,
        "height": 50
      }
    })
    super.set('performer', {
      "@type": "Organization",
      "name": "DF Alliance"
    })
  }

  setStartDate(date: string) {
    super.set('startDate', new Date(date).toISOString())
    return this;
  }
  setEndDate(date: string) {
    super.set('endDate', new Date(date).toISOString())
    return this;
  }

  setOffers(date: string) {
    super.set('offers', {
      "@type": "Offer",
      url: BusinessEventScheme.url(this.path),
      price: '0',
      priceCurrency: "USD",
      availability: "https://schema.org/InStock",
      validFrom: new Date(date).toISOString()
    })
    return this;
  }

  setLocation(str: LocationEnum, url?: string) {
    if (str === 'online') {
      super.set('location', {
        "@type": "VirtualLocation",
        url: url as string
      })
    } else {
      const {name, addressLocality, addressCountry, streetAddress} = (() => {
        switch (str) {
          case 'Dubai Exhibition Centre, Expo City':
            return {
              name: 'Dubai Exhibition Centre, Expo City',
              addressLocality: 'Dubai',
              addressCountry: 'AE',
              streetAddress: 'Sheikh Zayed Rd - Dubai - United Arab Emirates'
            }
        }
      })()

      super.set('location', {
        "@type": "Place",
        name,
        address: {
          "@type": "PostalAddress",
          addressLocality,
          addressCountry,
          streetAddress
        }
      })
    }

    return this;
  }
}

export default BusinessEventScheme;
